<template>
    <div>
     <Row>
        <i-col span="12">申请开票类型：<span class="text-orange"> {{task.typeName}} <span v-if="task.content">[{{task.content}}]</span></span>
        ，应开票金额(含税)：<span class="money">{{formatMoney(invoiceAmount)}}</span>
        </i-col>
        <i-col span="12" class="text-right">
            <i-button type="error" size="small" class="m-r-5" @click="rejectInvoice()" v-if="!task.invoicedAmount">驳回申请</i-button>
            <i-button type="success" size="small" @click="confirmInvoice()">确认开票</i-button>
        </i-col>
    </Row>
    <Row class="task-amount">
        <i-col span="24">
            <Row v-for="(item,index) in payments" :key="index" style="margin:10px 0px;" :gutter="8">
                <i-col span="5">开票日期： <DatePicker size="small" placeholder="选择开票日期" v-model="item.date" :clearable="false" style="width: 200px"/> </i-col>
                <i-col span="5">开票金额(含税)： <Input size="small" prefix="logo-yen" number v-model="item.money" @on-focus="currentPaymoney = item.money" @on-blur="handleItemChanged(item)" placeholder="输入支付金额" style="width: 200px" /></i-col>
                <i-col span="5">发票号码： <Input size="small"  v-model="item.code" placeholder="输入发票号码" style="width: 200px" /></i-col>
                <i-col span="6">发票链接： <Input size="small"  v-model="item.link" placeholder="电子发票链接" style="width: 300px" /></i-col>

                <i-col span="3"><a v-if="index" class="delete" @click="paymentItemRemove(item,index)">删除</a></i-col>
            </Row>
        </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { invalidInvoiceApplication } from '@/api/scp/invoiceapplication'
import { addinvoices, getinvoicesummary } from '@/api/scp/invoice'

export default {
  props: {
    task: Object,
    id: Number
  },
  data () {
    return {
      invoiceAmount: this.task.amount - this.task.invoicedAmount,
      payments: [{
        money: (this.task.amount - this.task.invoicedAmount),
        date: new Date(),
        code: '',
        link: ''
      }],

      currentPaymoney: 0,

      taskTypes: [],
      taskList: []
    }
  },
  created () {
    // this.resetPayItems()
  },
  methods: {
    paymentItemRemove (payment, index) {
      this.payments.splice(index, 1)
      const tmp = payment.money * 1
      this.payments[this.payments.length - 1].money = this.payments[this.payments.length - 1].money * 1 + tmp
    },
    paymentItemAdd () {
      const payed = this.computePayed()
      const residuum = this.invoiceAmount - payed

      if (residuum > 0) {
        this.payments.push({
          date: this.payments[this.payments.length - 1].date,
          code: '',
          link: '',
          money: residuum.toFixed(2)
        })
      }
    },
    computePayed () {
      let payed = 0.00
      this.payments.forEach(item => {
        payed += Number(item.money)
      })
      return payed
    },
    handleItemChanged (item) {
      const payed = this.computePayed()
      const residuum = this.invoiceAmount - payed
      if (residuum < 0) {
        this.$Notice.error({
          desc: '您所配置的付款额已超出总金额:' + Math.abs(residuum.toFixed(2)) + '元,已经为您还原价格'
        })
        // 还原到最低价
        item.money = this.currentPaymoney
      } else {
        this.paymentItemAdd()
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    // 确认开票
    confirmInvoice () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认已经填写完整的开票信息？',
        onOk: () => {
          var dataArray = []
          var canSubmit = true
          let invoiceAmount = 0
          this.payments.forEach(item => {
            if (item.code && item.money) {
              invoiceAmount += parseFloat(item.money)
              dataArray.push({
                amount: item.money,
                code: item.code,
                invoiceapplicationId: this.task.id,
                date: ParseDate(item.date),
                content: item.content,
                link: item.link
              })
            } else {
              canSubmit = false
            }
          })
          if (!canSubmit) {
            this.$Notice.error({ desc: '请正确并完整的填写开票信息！' })
            return
          }
          // 判断开票金额是否超出限制
          getinvoicesummary({ relateId: this.id }).then(res => {
            if (invoiceAmount > res.totalUnInvoiceAmount) {
              this.$Notice.error({ desc: '开票金额(含税)超出开票限制，开票失败！' })
            } else {
              addinvoices({ invoicesJsonStr: JSON.stringify(dataArray) }).then(res => {
                if (res && !res.errcode) {
                  this.$Notice.success({ desc: '开票成功' })
                  this.$emit('on-reloadData')
                }
              })
            }
          })
        }
      })
    },
    // 驳回
    rejectInvoice () {
      let desc = ''
      this.$Modal.confirm({
        title: '驳回提示',
        render: h => {
          return h('Input', {
            attrs: {
              placeholder: '请输入驳回说明'
            },
            props: {
              value: desc
            },
            on: {
              'on-change': event => {
                desc = event.target.value
              }
            }
          })
        },
        onOk: () => {
          invalidInvoiceApplication({ invoiceapplicationId: this.task.id, invalidMark: desc }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '驳回成功' })
              this.$emit('on-reloadData')
            }
          })
        }
      })
    }
  },
  watch: {
    task (val) {
      this.invoiceAmount = val.amount - val.invoicedAmount
      this.payments = [{
        money: (val.amount - val.invoicedAmount),
        date: new Date(),
        code: ''
      }]
      this.currentPaymoney = 0
    }
  }
}
</script>
